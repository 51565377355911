<template>
  <div class="wrapper-detail">
    <MemberInfo :member="detail.member" :detailInfo="detail" />

    <!-- 客户评级 -->
    <template v-if="!!detail.store">
      <LevelProgress
        :scoreProfileData="scoreProfileData"
        :finalLevel="finalLevel"
        :sysScore="sysScore"
        :sysLevel="sysLevel"
      />
    </template>

    <!-- 订单信息 -->
    <OrderInfo :detailInfo="detail" />

    <!-- 账期 -->
    <template v-if="!!detail.store">
      <PeriodStoreInfo v-if="detail.orderBillList" :detailInfo="detail" />
    </template>
    <template v-else>
      <PeriodInfo ref="period" :orderBillList="detail.orderBillList" />
    </template>

    <!-- 门店信息 -->
    <template v-if="!!detail.store">
      <StoreInfo
        :detailInfo="detail"
        :flag.sync="flag"
        @updateDetail="getDetail"
      />
    </template>

    <!-- 紧急联系人 -->
    <ContactInfo
      :type="1"
      :member="detail.member"
      :orderInfo="detail"
      :flag.sync="flag"
      @updateDetail="getDetail"
    />

    <!-- 订单备注 -->
    <ContactInfo
      :type="2"
      :flag.sync="flag"
      :member="detail.member"
      :orderInfo="detail"
      @updateDetail="getDetail"
    />

    <!-- 收货信息 -->
    <AddressInfo
      :flag.sync="flag"
      :orderAddress="detail.orderAddress"
      :status="detail.status"
      @updateDetail="getDetail"
    />

    <!-- 发货信息 -->
    <DeliveryInfo
      :orderDelivery="detail.orderDelivery || {}"
      :detail="detail"
    />
  </div>
</template>

<script>
import { getDetailAPI } from "./api";
import MemberInfo from "./modules/member-info.vue";
import OrderInfo from "./modules/order-info.vue";
import StoreInfo from "./modules/store-info.vue";
import ContactInfo from "./modules/contact-info.vue";
import AddressInfo from "./modules/address-info.vue";
import DeliveryInfo from "./modules/delivery-info.vue";
import PeriodStoreInfo from "./modules/period-store-info.vue";
import PeriodInfo from "./modules/period-info.vue";

export default {
  name: "DetailBase",
  components: {
    MemberInfo,
    OrderInfo,
    StoreInfo,
    ContactInfo,
    AddressInfo,
    DeliveryInfo,
    PeriodStoreInfo,
    PeriodInfo,
  },
  data() {
    return {
      detail: {},
      flag: true,
    };
  },
  computed: {
    finalLevel() {
      return this.detail.orderScoreProfile
        ? this.detail.orderScoreProfile.finalHitGrade
        : "";
    },
    sysScore() {
      return this.detail.orderScoreProfile
        ? this.detail.orderScoreProfile.sysHitScore
        : "";
    },
    sysLevel() {
      return this.detail.orderScoreProfile
        ? this.detail.orderScoreProfile.sysHitGrade
        : "";
    },
    scoreProfileData() {
      return this.detail.orderScoreProfile || {};
    },
  },
  mounted() {},
  activated() {
    this.getDetail();
  },
  methods: {
    goJiShen() {
      this.$emit("goJS");
    },
    async getDetail() {
      this.detail = await getDetailAPI(this.$route.params.id);
    },
    closePage() {
      this.emitAwindow();
      window.close();
    },
    emitAwindow() {
      //window.opener 获取父页面的window元素
      //判断A窗口有没有window.opener和getBpageList是不是个方法
      if (window.opener && window.opener.getBpageList) {
        window.opener.getBpageList();
      } else {
        //window.opener.frames[0] 获取到的window对象
        window.opener.frames[0].getBpageList();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.wrapper-detail {
  width: 100%;
  flex: 1;
  overflow-y: scroll;
  margin-top: 15px;
  // overflow-x: hidden;

  .title {
    font-weight: 600;
    margin: 20px 0;
    &:first-child {
      margin-top: 0;
    }
  }
}
.without-layout .wrapper-detail {
  padding: 0 14% !important;
}
</style>
