var render = function render(){var _vm=this,_c=_vm._self._c;return _c('card',{staticClass:"goods-info"},[_c('AdminTitle',{attrs:{"title":"订单信息","size":"20px"}}),_c('div',{staticClass:"goods-info-detail"},[_c('el-image',{staticStyle:{"width":"100px","border-radius":"8px","height":"100px","margin-right":"20px"},attrs:{"src":_vm.detailInfo.orderGoods ? _vm.detailInfo.orderGoods.skuCoverImageUrl : '',"preview-src-list":[
        _vm.detailInfo.orderGoods ? _vm.detailInfo.orderGoods.skuCoverImageUrl : '',
      ]}},[_c('div',{staticStyle:{"background":"#f5f7fa","width":"100px","height":"100px","line-height":"100px","text-align":"center","color":"#c0c4cc"},attrs:{"slot":"error"},slot:"error"},[_vm._v(" 暂无图片 ")])]),_c('div',{staticClass:"goods-info-detail-right"},[_c('div',{staticClass:"goods-title fw700"},[_vm._v(" "+_vm._s(_vm.detailInfo.orderGoods ? _vm.detailInfo.orderGoods.skuTitle : "")+" ")]),_c('div',{staticClass:"goods-tag"},[_c('el-tag',{attrs:{"type":_vm.detailInfo.orderGoods && _vm.detailInfo.orderGoods.goodsPurityName
              ? _vm.detailInfo.orderGoods.goodsPurityName == '全新'
                ? 'success'
                : 'warning'
              : 'success'}},[_vm._v(_vm._s(_vm.detailInfo.orderGoods ? _vm.detailInfo.orderGoods.goodsPurityName : ""))]),_c('el-tag',{attrs:{"type":"success"}},[_vm._v(_vm._s(_vm.detailInfo.orderTypeName))]),(_vm.detailInfo.enableAlipayFundAuth)?_c('el-tag',{attrs:{"type":"danger"}},[_vm._v("免押")]):_vm._e(),_c('el-tag',{attrs:{"type":"info"}},[_vm._v(_vm._s(_vm.detailInfo.statusName))])],1),_c('div',{staticClass:"label"},[_vm._v("订单号："+_vm._s(_vm.$route.params.id))]),_c('div',{staticClass:"label"},[_vm._v("下单时间："+_vm._s(_vm.detailInfo.createTime))])])],1),_c('div',{staticClass:"cost-details"},[_c('div',{staticClass:"cost-title"},[_vm._v("费用明细")])]),_c('card',{staticClass:"goods-info special-card"},[_c('el-row',_vm._l((_vm.goodsInfoList),function(item,index){return _c('el-col',{key:index,attrs:{"span":item.width ? item.width : 8}},[_c('span',{staticClass:"label"},[_vm._v(_vm._s(item.label))]),_c('span',{staticClass:"value"},[_vm._v(_vm._s(_vm.detailInfo[item.prop] || "")+_vm._s(item.unit))])])}),1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }